<template>
  <section>
    <b-card>
      <template #header>
        <div class="d-flex align-items-center">
          <span class="text-primary">Fornitori </span>
          <b-breadcrumb class="ml-2">
            <b-breadcrumb-item>
              <feather-icon
                icon="HomeIcon"
                scale="1.25"
                shift-v="1.25"
              ></feather-icon>
            </b-breadcrumb-item>
            <b-breadcrumb-item active> Elenco Fornitori</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
        <div>
          <b-button variant="primary" @click="$router.push('/suppliers/add')">
            Aggiungi Fornitore
          </b-button>
        </div>
      </template>

      <b-row class="mt-2">
        <b-col md="9"> </b-col>

        <b-col md="3">
          <div class="d-flex align-items-center mb-2">
            <span class="mr-1">Cerca</span>
            <b-form-input type="text" v-model="filter" size="md" />
          </div>
        </b-col>
      </b-row>

      <b-table
        :fields="fields"
        :items="suppliers"
        :busy="loading"
        :filter="filter"
        :per-page="perPage"
        :current-page="currentPage"
        striped
        show-empty
        responsive
      >
        <template #table-busy>
          <div class="text-center text-primary-light my-2">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong>Caricamento dati dei fornitori...</strong>
          </div>
        </template>

        <template #emptyfiltered>
          <h5 class="text-primary-light text-center my-2">
            Non sono stati trovati fornitori
          </h5>
        </template>

        <template #cell(id_fornitore)="data">
          {{data.item.id_supplier}}
        </template>

        <template #cell(nome_fornitore)="data">
          {{ data.item.supplier_company }}
        </template>

        <template #cell(auto_acquistate_presso_il_fornitore)="data">
          {{data.item.supplier_car_purchased}}
        </template>

        <template #cell(ultimo_acquisto)="data">
          {{data.item.supplier_last_purchase}}
        </template>

        <template #cell(totale_fatturato_anno)="data">
          {{data.item.supplier_gross}}
        </template>

        <template #cell(totale_fatturato_anno_precedente)="data">
          {{data.item.supplier_gross_last_year}}
        </template>

        <template #cell(variazione_%)="data">
          {{data.item.supplier_gross_var_perc}} %
        </template>

        <template #cell(opzioni)="data">
          <span class="text-nowrap">
          <feather-icon class="cursor-pointer mr-2" icon="EyeIcon" size="20" @click="$router.push('/suppliers/view/' + data.item.id_supplier)" v-b-tooltip.hover.top.v-primary-light="'Dettaglio'"/>
          <feather-icon class="cursor-pointer" icon="EditIcon" size="20" @click="$router.push('/suppliers/edit/' + data.item.id_supplier)" v-b-tooltip.hover.top.v-primary-light="'Modifica'"/>
          </span>
        </template>
      </b-table>
    </b-card>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BBadge,
  BButton,
  BBreadcrumb,
  BBreadcrumbItem,
  BCard,
  BTable,
  BSpinner,
  BFormInput,
  VBTooltip,
} from "bootstrap-vue";

import { Requests } from "@/api";

export default {
  components: {
    BRow,
    BCol,
    BBadge,
    BButton,
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    BTable,
    BSpinner,
    BFormInput,
    VBTooltip,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  data: () => ({
    fields: [
      {
        key: "id_fornitore",
        sortable: true,
      },
      {
        key: "nome_fornitore",
        sortable: true,
      },
      {
        key: "auto_acquistate_presso_il_fornitore",
        sortable: true,
      },
      {
        key: "ultimo_acquisto",
        sortable: true,
      },
      {
        key: "totale_fatturato_anno",
        sortable: true,
      },
      {
        key: "totale_fatturato_anno_precedente",
        sortable: true,
      },
      {
        key: "variazione_%",
        sortable: true,
      },
      {
        key: "opzioni",
        sortable: false,
      },
    ],

    filter: null,
    suppliers: [],

    //Helpers

    perPage: 15,
    currentPage: 1,
    loading: false,
  }),

  created() {
    this.getSuppliers();
  },

  methods: {
    async getSuppliers() {
      this.loading = true;

      try {
        const response = await Requests.getSuppliersList();
        this.suppliers = response;
      } catch (err) {
        console.debug(err);
      }

      this.loading = false;
    },
  },
};
</script>
